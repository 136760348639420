<template>
  <div class="product-page">
    <v-row no-gutters class="mt-2">
      <v-col class="page-header">
        <span class="page-title">{{ $t('Product Options') }}</span>
      </v-col>

      <v-spacer></v-spacer>
      <v-col class="text-right">
        <v-btn color="primary" @click="$router.push({ name: 'website.menufilter.create' })"
          ><i class="fas fa-plus mr-1"></i>{{ $t('Add options') }}</v-btn
        >
      </v-col>
      <v-col cols="12">
        <p>
          Show filter options on collections page in Fashion theme
        </p>
      </v-col>
    </v-row>

    <v-row no-gutters class="elevation-1 page__table-container mt-10">
      <HeaderTableTabs :currentTab.sync="currentTab" :tabs="tabs" />
      <v-col>
        <Resource
          :callback-action="getAction"
          :callback-selection="getIds"
          :actionItems="items"
          :document="document"
          :draggable="true"
          :columns="columns"
          :update-router-callback="getUpdateRouterData"
        />
      </v-col>
    </v-row>
    <v-dialog persistent v-model="showModalDelete" width="500" style="transform-origin: center top">
      <v-card relative>
        <!-- Title -->
        <v-card-title class="headline">
          {{ 'Delete' }} {{ this.selectAll === 'all' ? parseInt(countt) : ids.length }} products?.
        </v-card-title>
        <div class="close-icon" @click="showModalDelete = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-divider class="mb-3"></v-divider>
        <v-card-text class="">
          Are you sure you want to delete {{ this.selectAll === 'all' ? parseInt(countt) : ids.length }} products. This
          action cannot be reversed.
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="showModalDelete = false"> Cancel </v-btn>
          <v-btn :loading="isLoadingButton" color="error" @click="onSubmitDelete"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Resource from '@/components/Resources';
import resourceListMixin from '@/mixins/resource-list';
import { menuFilterApi } from '@/apis/menufilter';
import event from '@/plugins/event-bus';

export default {
  mixins: [resourceListMixin],
  components: { Resource },
  data() {
    return {
      selectAll: '',
      showModalDelete: '',
      ids: [],
      idSelected: '',
      resourceType: 'menufilter',
      searchableField: 'with title',
      columns: {
        Selectable: {
          blankLabel: true,
          kind: 'selectable',
          width: 1,
        },
        Draggerable: {
          blankLabel: true,
          kind: 'draggerable',
          width: 1,
        },
        Title: {
          kind: 'title-menufilter',
          field: 'title',
        },
        Available: {
          kind: 'menufilter',
          field: 'available',
        },
        Value: {
          kind: 'value-menufilter',
          field: 'value',
        },
      },
      count: 0,
      tabs: [{ name: 'All', key: '' }],
      items: [{ title: 'Delete selected options', action: 'remove-product' }],
      executor: null,
      isLoadingButton: false,
      isMount: false,
      updateRouteName: 'website.menufilter.update',
    };
  },
  computed: {
    test() {
      return `background-image : url("https://minio.lattehub.com/img/200/200/resize/610e57f8d7768100099c00e5/2021/08/13/lattehub-image-6115ec57e9ff659c2b811362.jpg");`;
    },
  },
  watch: {
    document: {
      handler: function() {
        if (this.isMount && !this.isLoading && this.document.length > 0 && this.count > 0) {
          if (this.executor) {
            clearTimeout(this.executor);
            this.executor = null;
          }
          this.executor = setTimeout(async () => {
            this.document.forEach(async (element, k) => {
              try {
                await menuFilterApi.putFilter({ ...element, position: k }, element._id);
                event.$emit('success-api');
              } catch (error) {
                console.log('error', error);
              }
            });
          }, 500);
        }
        this.count = this.count + 1;
      },
      deep: true,
    },
  },

  async mounted() {
    console.log('child mounted');
    this.isMount = true;
  },
  methods: {
    getAction(action, ids) {
      this.ids = ids;
      this.action = action.action;
      if (action.action === 'bulk-edit') {
        this.$router.push({
          name: 'website.menufilter.update',
          // query: {
          //   ids: ids.join(','),
          // },
        });
      }
      if (action.action === 'make-available' || action.action === 'make-unavailable') {
        this.showModelAvailable = true;
      }
      if (action.action === 'remove-product') {
        this.showModalDelete = true;
      }
    },
    getIds(ids) {
      this.idSelected = ids;
      this.ids = ids;
    },
    async onSubmitDelete() {
      try {
        this.isLoadingButton = true;
        await menuFilterApi.deleteFilter(`?ids=${this.ids.join(',')}`);
        this.isLoading = true;
        this.showModalDelete = false;
        this.isLoading = false;
      } catch (error) {
        //empty
      }
      this.showModalDelete = false;
      this.isLoadingButton = false;
      this.getResources();
    },
  },
};
</script>

<style></style>
